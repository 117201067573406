import React from "react"
import "../styles/privacy.scss"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import JobForm4 from "../components/job-application-form4"

const ApplicationPerformanceEngineer = () => (
  <Layout>
    <ScrollArrow showBelow={450} />

    <SEO title="Application Performance Engineer" />
    <div className="job-vac-section">
      <div className="eng-chal-content">
        <div className="transparent">
          <div className="outer-container">
            <div className="inner-container">
              <div className="column">
                <h1>Application Performance Engineer</h1>
                <h2>Job Vacancy Information and Application Form</h2>
                <h3>Contact us and send us your CV for review.</h3>
                {/* <ButtonB href="/about">Our Story</ButtonB> */}
              </div>
            </div>

            <div className="outer-container">
              <div className="inner-container" style={{ textAlign: "left" }}>
                <div style={{ width: "100%" }}>
                  <h3>Job Title</h3>
                  <div className="aut-div">
                    <p>Application Performance Engineer</p>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <h3>Department</h3>
                  <div className="aut-div">
                    <p>Application Performance Team</p>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <h3>Location</h3>
                  <div className="aut-div">
                    <p>
                    Must be a US citizen. Flexible - working from home, office or on customer sites
                    </p>
                  </div>
                </div>

                <div>
                  <h3>Job Description</h3>
                  <div className="aut-div">
                    <p>
                      Install and configure AppDynamics. Work with AppDynamics
                      tool to instrument applications to be monitored typically
                      in a cloud environment. Build custom dashboards to help
                      highlight key metrics within the customer's
                      application(s). Usually remote work, possibly some travel.
                      Must be a US citizen.{" "}
                    </p>
                  </div>
                </div>

                <div>
                  <h3>Preferred Skills:</h3>
                  <div className="aut-div">
                    <ul>
                      <li>
                        • AppDynamics experience or similar Application
                        Performance Management (APM) or monitoring tools such as
                        Splunk, ELK/Kibana{" "}
                      </li>

                      <li>• Linux/Windows administration experience </li>
                      <li>• Java and .NET experience </li>
                      <li>• Performance deep dive of JVM/CLR analysis to examine garbage collection, thread and heap dumps etc.</li>
                      <li>• Experience working with database technology such as Oracle, SQL, PostgreSQL, MongoDB, or Cassandra.</li>
                      <li>• Experience with Docker and Kubernetes.</li>
                      <li>• Micro Focus Performance Center / LoadRunner.</li>
                    </ul>
                  </div>
                </div>

                <div>
                  <br />
                  <h3>Required Skills:</h3>
                  <div className="aut-div">
                    <ul>
                      <li>
                        {" "}
                        • Some programming experience{" "}
                      </li>
                      <li> • Some database experience </li>
                      <li> • Understanding the OSI model </li>
                      <li> • Comfortable using Linux terminal </li>
                      <li> • Familiar with common performance bottlenecks in computing </li>
                    </ul>
                  </div>

                <div>
                  <br />
                  <h3>About Metsi</h3>
                  <div className="aut-div">
                    <p>
                      Metsi Technologies Inc., based in Nashville, TN, is a
                      digital systems integrator that accelerates digital
                      transformation for the IT enterprise through continuous
                      digital innovation, digital applications, hybrid
                      multicloud adoption, microservices, software defined, and
                      Anything-as-a-Service (XaaS). We create the secure IT
                      flexibility that meets business demand.
                      <br />
                      Metsi’s goal is to deliver fast and comprehensive
                      technology modernization, based on best of breed
                      technologies, both COTS and open source; compelling
                      consulting and thought leadership; and stellar engineering
                      delivery. We believe in excellence, integrity, and
                      comradery.
                      <br /> Metsi is an OEM services partner of Cisco,
                      ServiceNow, RedHat, Microsoft, VMware, AppDynamics,
                      Dynatrace, Morpheus, CloudBolt, Spectro Cloud, NetBrain,
                      and PMG . Metsi also holds VAR services partnerships with
                      WWT, Technologent, Trace3, Sirius Computer Solutions,
                      CyKor, and Synnex.{" "}
                    </p>
                    <h3>Apply Now:</h3>
                  </div>
                </div>
                </div>

                <JobForm4 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ApplicationPerformanceEngineer
